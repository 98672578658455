import { formatters } from 'utils'

const messages = {
  isRequired: () => 'campo obrigatório',
  isCpf: () => 'digite um CPF válido',
  isZipCode: () => 'digite um CEP válido',
  isGreaterOrEqualThan: (min) =>
    `o valor deve ser maior ou igual a ${formatters.number.toCurrency(min)}`,
  isLesserOrEqualThan: (max) =>
    `o valor deve ser menor ou igual a ${formatters.number.toCurrency(max)}`,
  isBetween: ({ min, max, message }) =>
    message
      ? message({ min, max })
      : `escolha um valor entre <b>${min} e ${max}</b>`,
  isPhone: () => `digite um celular válido`,
  isDate: () => `digite uma data válida`,
  isValidEmail: () => `Digite um E-mail válido`,
  isLegalAge: () => `você deve possuir mais de 18 anos`,
  isCnhValid: () => `Digite uma CNH válida`,
  isRneValid: () => `Digite um RNE válido`,
  isRgValid: () => `Digite um RG válido`,
  wordsLengthBiggerThen: (value) =>
    `cada palavra deve ter no máximo ${value} letras`,
  wordsLengthLessThen: (value) =>
    `cada palavra deve ter no mínimo ${value} letras`,
  validateTriplicatedCharacters: () =>
    `Este campo não pode conter 3 letras iguais em sequência`,
  validateSpecialCharactersToName: () =>
    `Este campo não pode conter caracteres especiais`,
  fieldLengthLessThan: () => `nome é maior que o permitido`,
  validateDashesinEdges: () =>
    `Este campo não pode conter '-' (traço) no início ou no fim do nome`,
  validateMaxLength: () => `O campo possuí mais caracteres do que é permitido.`,
  isNumberOnly: () => `Este campo não pode conter somente números`,
  isGreaterThan: (maxValue) => `O valor não pode ser maior que ${maxValue}`,
  isNonNumeric: () => `O campo não pode conter números.`,
  isOnlyNumeric: () => `O campo só deve conter números.`,
  isPhoneValid: () => `Digite um telefone válido`,
  hasLengthOf: (value) => `Este campo deve ter ${value} caracteres.`,
  wordsMoreThen: () => `O campo não possuí o número de palavras suficiente`,
  isDDDValid: () => `O DDD informado é inválido`,
  isValidText: () =>
    `Este campo não aceita pontuações ou caracteres especiais.`,
  isValidName: () => `Preencha apenas com nome e sobrenome.`,
  validateIfHasSpecialCharactersOnly: () =>
    `O campo não pode possuir apenas caracteres especiais`,
  isDateFormat: () => `digite uma data válida`,
  dateIsBefore: () => `A data selecionada é maior do que a permitida.`,
  dateIsAfter: () => `A data selecionada é menor do que a permitida.`,
  containNameTitle: () =>
    `não use títulos em seu nome, como Sr, Sra, Dr, Dra e Eng.`,
  isNameComplete: () => `digite o nome completo`
}

export default messages
