import { useController } from 'react-hook-form'

import { DsIcon as Icon, If } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'

import utils from '../utils'
import './radio.scss'

const Radio = ({
  description,
  legend,
  name,
  options = [],
  rules = {},
  box,
  colClassName = '',
  card,
  onChange = () => {},
  disabled,
  control,
  gridClassName = ''
}) => {
  const validate = utils.hooks.useValidateHF(rules)

  const {
    field,
    fieldState: { isTouched, error }
  } = useController({
    name,
    control,
    rules: {
      validate
    }
  })

  const meta = {
    touched: isTouched,
    error: error?.message,
    value: field.value
  }

  const className = classnames('radio', {
    'radio--box': box,
    'radio--card': card
  })

  const classNames = utils.helpers.getClassnames(meta, className)
  const ariaDescribedBy = utils.helpers.getAriaDescribedBy(
    description,
    field.error,
    name
  )
  return (
    <fieldset id={name} className={classNames}>
      <legend className="radio__legend">{legend}</legend>

      <div className={`radio__grid ${gridClassName}`}>
        {options.map((option) => {
          const { label, id, icon } = option
          return (
            <div className={colClassName} key={id}>
              <div className="radio__input-wrapper">
                <input
                  {...field}
                  {...option}
                  aria-describedby={ariaDescribedBy}
                  checked={field.value === option.value}
                  id={id}
                  name={name}
                  className="radio__input"
                  type="radio"
                  onChange={(e) => {
                    field.onChange(e)
                    onChange()
                  }}
                  disabled={disabled}
                />
                <label
                  id={`label-${id}`}
                  className="radio__label"
                  data-cy={`cy-card-${id}`}
                  htmlFor={id}
                >
                  <If
                    condition={icon}
                    renderIf={
                      <Icon
                        name={icon?.name}
                        type={icon?.type}
                        isLocal={icon?.isLocal}
                      />
                    }
                  />
                  <span
                    className="radio__label-text"
                    dangerouslySetInnerHTML={{ __html: label }}
                  />
                </label>
              </div>
            </div>
          )
        })}
      </div>
    </fieldset>
  )
}

export default Radio
