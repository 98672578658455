import { IdsIcon } from '@ids/react'
import { If, DirectionalButton } from '@itau-loans-www/shopping/src/components'
import {
  finishProposalOnCardCallback,
  goToSimulationProposalCallback,
  webAppCallback
} from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import AdditionalData from '../../../../components/AdditionalData'
import './default-secondary-offer-card.scss'

const CardDefaultSecondOffer = (props) => {
  const {
    currentProposal: {
      title,
      titleArray,
      subtitle,
      icon,
      buttonText,
      productCode,
      buttonAriaLabel,
      buttonTrackingName,
      secondOfferHighlights,
      showInterestRate,
      interestRateMonthly,
      maxLoanValue
    }
  } = props

  const { number } = formatters

  const callbackButtonExceptions = {
    FGTS: goToSimulationProposalCallback,
    fgts_app: goToSimulationProposalCallback,
    'CONSIG CORR OP / Person': goToSimulationProposalCallback,
    'CONSIG CORR EP / Person': goToSimulationProposalCallback,
    'CONSIG CORR EP': goToSimulationProposalCallback,
    'CONSIG CORR OP': goToSimulationProposalCallback,
    consig_refin_inss_ncor: goToSimulationProposalCallback,
    consig_ncor: goToSimulationProposalCallback,
    consortium: webAppCallback
  }

  const hasEditScreen =
    callbackButtonExceptions[productCode] === goToSimulationProposalCallback

  const callbackButtonFunction =
    callbackButtonExceptions[productCode] || finishProposalOnCardCallback

  const hasMaxLoanValue = 'maxLoanValue' in props.currentProposal

  const hasPreApprovedFlag = 'showPreApprovedValue' in props.currentProposal

  const coldOffer =
    !hasPreApprovedFlag && !(hasMaxLoanValue && maxLoanValue > 0)

  return (
    <div className="secondary-offer-card">
      <div className="secondary-offer-card__header">
        <IdsIcon size="L" variant="primary">
          {icon}
        </IdsIcon>
        <If
          condition={title}
          renderIf={
            <p className="secondary-offer-card__title secondary-offer-card__title--unique">
              {title}
            </p>
          }
          renderElse={
            <span>
              {titleArray?.map((item, index) => (
                <p className="secondary-offer-card__title" key={index}>
                  {item}
                </p>
              ))}
            </span>
          }
        />
      </div>

      <div className="secondary-offer-card__main-content">
        <If
          condition={!coldOffer}
          renderIf={
            <div className="secondary-offer-card__approved-value">
              <p>Pré aprovado</p>
              <p>{number.toCurrency(maxLoanValue)}</p>
            </div>
          }
          renderElse={
            <p className="secondary-offer-card__subtitle">{subtitle}</p>
          }
        />

        <AdditionalData
          list={secondOfferHighlights}
          interestRateMonthly={interestRateMonthly}
          showInterestRate={showInterestRate}
        />
      </div>

      <div className="secondary-offer-card__final-content --debts">
        <DirectionalButton
          {...props}
          hasEditScreen={hasEditScreen}
          coldOffer={coldOffer}
          buttonText={buttonText}
          callback={callbackButtonFunction}
          buttonAriaLabel={buttonAriaLabel}
          id={`second-offer-${productCode}-btn`}
          dataTestid={`secondary-offer-${productCode}-btn`}
          buttonTracking={`OutrasOfertas:${buttonTrackingName}`}
        />
      </div>
    </div>
  )
}

export default CardDefaultSecondOffer
