import { IdsLoading } from '@ids/react'
import classnames from 'classnames'

import './loader.scss'

const Loader = ({
  visible = false,
  hasOverlay = true,
  contrast = 'onBrand',
  size = 'XL',
  bgColor = 'orange'
}) => {
  const classNames = classnames('loader', {
    'loader--hidden': !visible,
    'loader--has-overlay': hasOverlay
  })

  return (
    <div
      className={classNames}
      role="alert"
      aria-label="carregando"
      data-testid="loader"
      data-bg-color={bgColor}
    >
      <IdsLoading show={visible} contrast={contrast} size={size} />
    </div>
  )
}

export default Loader
