import SeeOffersButton from '../SeeOffersButton'
import './see-offers-section.scss'

const SeeOffersSection = ({ trackClick }) => {
  return (
    <div className="seeoffers">
      <div className="seeoffers__wrapper">
        <div className="seeoffers__titles">
          <h2 className="seeoffers__title">
            O Itaú tem diversas ofertas de empréstimos ideais para o seu perfil
          </h2>
        </div>
        <div className="seeoffers__card">
          <strong className="seeoffers__subtitle">
            São diversas opções com
          </strong>
          <strong className="seeoffers__tax">taxas a partir de 0,89% </strong>
          <p className="seeoffers__description">
            Responda nossas perguntas e conheça agora, leva menos de 5 minutos!
          </p>

          <SeeOffersButton
            isContextual={true}
            isCrossSell={true}
            id="cross-sell-btn"
            trackingName={trackClick}
            text={'Ver minhas ofertas'}
          />
        </div>
      </div>
    </div>
  )
}

export default SeeOffersSection
