import { IdsIcon } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'
import { formatters } from 'utils'

import './card-additional-data.scss'

const AdditionalData = ({
  list,
  interestRateMonthly = 0,
  showInterestRate = false,
  className,
  children = null
}) => {
  const { number } = formatters

  const classNames = classnames('card-additional-data', {
    [className]: className
  })

  return (
    <ul className={classNames}>
      <If condition={children} renderIf={children} />

      <If
        condition={showInterestRate}
        renderIf={
          <li className="item --divider-bottom">
            <p className="card-additional-data__infos-title">Taxa de juros</p>
            <p className="card-additional-data__infos-description">
              {number.toPercent(interestRateMonthly, 2)} ao mês
            </p>
          </li>
        }
      />

      {list?.map((item, index) => {
        const { icon, text, infosTitle, infosDescription } = item
        return (
          <li
            className={`item ${
              index !== 0 && index < list.length ? '--divider-top' : ''
            }`}
            key={index}
            style={{ order: index }}
          >
            <If
              condition={infosTitle}
              renderIf={
                <>
                  <p className="card-additional-data__infos-title">
                    {infosTitle}
                  </p>
                  <p className="card-additional-data__infos-description">
                    {infosDescription}
                  </p>
                </>
              }
              renderElse={
                <>
                  {icon && <IdsIcon>{icon}</IdsIcon>}
                  <p className="card-additional-data__text">{text}</p>
                </>
              }
            />
          </li>
        )
      })}
    </ul>
  )
}

export default AdditionalData
