import { Button } from '@itau-loans-www/shopping/src/components'
import { useLpControl, useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import classnames from 'classnames'
import { goTo } from 'utils'

import data from './data.js'
import './simulate-button.scss'

const SimulateButton = () => {
  const { hideSimulateButton } = useLpControl()
  const isMobile = useMediaQuery('mobileLandscape')
  const buttonText = isMobile ? data?.mobileBtn : data?.btn

  const classNames = classnames('simulate', {
    '--hide': hideSimulateButton
  })

  return (
    <div data-testid="simule-btn-component" className={classNames}>
      <div className="container">
        <h1 className="simulate__title">{data?.title}</h1>
        <p className="simulate__subtitle">{data?.subtitle}</p>
        <div className="simulate__btn-wrapper">
          <Button
            data-testid="simule-btn"
            className="simulate__btn"
            fluid
            onClick={() => {
              goTo('basic-data')
            }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SimulateButton
