import classnames from 'classnames'

const getClassnames = (meta, className = '') => {
  return classnames({
    [className]: className,
    '-focus': meta.focus,
    '-error': meta.touched && meta.error,
    '-valid': meta.touched && !meta.error,
    '-value': !meta.error && meta.value
  })
}

const getAriaDescribedBy = (description, error, id) => {
  let ariaDescribedBy = ''

  if (description) {
    ariaDescribedBy = ariaDescribedBy.concat(`description_${id} `)
  }

  if (error) {
    ariaDescribedBy = ariaDescribedBy.concat(`error_${id}`)
  }

  return ariaDescribedBy
}

const getAriaRequired = (rules) => rules['isRequired']

const getAriaInvalid = (meta) => !!(meta.touched && meta.error)

export default {
  getClassnames,
  getAriaDescribedBy,
  getAriaRequired,
  getAriaInvalid
}
