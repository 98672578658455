import isCep from '@mobi/utils/validators/cep'
import isCpf from '@mobi/utils/validators/cpf'
import isDate from '@mobi/utils/validators/date'
import isValidEmail from '@mobi/utils/validators/email'
import isLegalAge from '@mobi/utils/validators/legalAge'
import isPhone from '@mobi/utils/validators/phone'

const helpers = {
  isSecureText: (text = '', customValidator) => {
    if (!text) text = ''

    if (text === '') return true

    const textWithoutSpaces = text.replace(helpers.removeSpacesRegex, ''),
      textCollection = textWithoutSpaces.split(/\s+/)

    const validatorPerWord = (validator) =>
      textCollection.every((word) => {
        let wordIsAValidText = !word.match(helpers.validCharRegex)

        return validator
          ? wordIsAValidText && validator(word)
          : wordIsAValidText
      })

    return (
      !textWithoutSpaces.match(helpers.validCharRegex) &&
      validatorPerWord(customValidator)
    )
  },
  removeSpacesRegex: /^\s+|\s+$/gm,
  validCharRegex: /[ªº$&+,:;=?@#|<>.^*()%_!-]/gi
}

const validators = {
  isRequired: (value) => !value,
  isZipCode: (value) => !isCep(value),
  isCpf: (value) => !isCpf(value),
  isGreaterOrEqualThan: (value, maxValue) => {
    if (value) {
      let stringValue = value + ''

      if (stringValue.startsWith('R$')) {
        stringValue = stringValue.split('R$ ')[1]
      }

      if (!stringValue) return

      let onlyNums = stringValue.split(',')[0].match(/\d+/gi).join('')

      if (onlyNums >= maxValue) {
        return false
      }
      return true
    }
  },
  isLesserOrEqualThan: (value, maxValue) => {
    if (value) {
      let stringValue = value + ''
      let onlyNums = stringValue.split(',')[0].match(/\d+/gi).join('')

      if (onlyNums <= maxValue) {
        return false
      }
      return true
    }
  },
  isPhone: (value) => !isPhone(value, 11, 11, 'cellphone'),
  isDate: (value) => {
    if (!value) return true
    const parms = value.split('-'),
      year = parseInt(parms[0], 10),
      month = parseInt(parms[1], 10),
      day = parseInt(parms[2], 10),
      currentDate = new Date(),
      currentYear = currentDate.getFullYear(),
      currentDay = currentDate.getDate(),
      currentMonth = currentDate.getMonth() + 1
    if (
      month > 12 ||
      day > 31 ||
      year > currentYear ||
      year < 1880 ||
      year > currentYear ||
      (year >= currentYear && month > currentMonth) ||
      (year >= currentYear && month === currentMonth && day > currentDay)
    ) {
      return true
    }

    let reference = new Date(year, month - 1, day),
      refYear = reference.getFullYear(),
      refDay = reference.getDate(),
      refMonth = reference.getMonth() + 1

    if (refYear !== year || refMonth !== month || refDay !== day) {
      return true
    }

    return false
  },
  isDateFormat: (value = '') => {
    const date = value.split('-')
    const year = date[0]
    const month = date[1]
    const day = date[2]
    const formatedDate = `${day}/${month}/${year}`

    if (isDate(formatedDate)) {
      return true
    }

    return false
  },
  dateIsBefore: (date, limit) => {
    const selectedDate = new Date(date).getTime()
    const limitDate = new Date(limit).getTime()

    if (selectedDate <= limitDate) return false

    return true
  },
  dateIsAfter: (date, limit) => {
    const selectedDate = new Date(date).getTime()
    const limitDate = new Date(limit).getTime()

    if (selectedDate >= limitDate) return false

    return true
  },
  isLegalAge: (value) => !isLegalAge(value),
  isValidEmail: (value) => !isValidEmail(value),
  isCnhValid: (value = '') => value && value.length !== 11,
  isRneValid: (value) => value && value.length !== 9,
  hasLengthOf: (value = '', length) => value.length !== length,
  wordsMoreThen: (text, wordsNum) => {
    if (!text) {
      return true
    }

    const wordsArr = text.trim().split(/\s/)
    return wordsArr.length < wordsNum
  },
  isGreaterThan: (value, maxValue) => {
    if (value) {
      const stringValue = value + ''
      const onlyNums = stringValue.split(',')[0].match(/\d+/gi).join('')

      if (onlyNums < maxValue) {
        return false
      }
      return true
    }
  },
  wordsLengthLessThen: (text, length) => {
    if (!text) return true

    let wordsArr = text.trim().split(/\s/)

    return !wordsArr.every((word) => word.length >= length)
  },
  wordsLengthBiggerThen: (text, length) => {
    if (!text) return true

    let wordsArr = text.trim().split(/\s/)

    return !wordsArr.every((word) => word.length <= length)
  },
  validateTriplicatedCharacters: (text) => {
    if (!text) return true

    return text.trim().match(/(.)\1{2,}/i)
  },
  validateSpecialCharactersToName: (text) => {
    if (!text) return true

    return text.trim().match(/[~!@#$%^&*()_+={}[\]|\\:;<>,./?]/gi)
  },
  validateDashesinEdges: (text) => {
    if (!text) return true

    return text.trim().match(/^-+|-+$/gm)
  },
  validateMaxLength: (value = '', maxLength) => value.length > maxLength,
  isNumberOnly: (value) => {
    if (!value) {
      return true
    }

    return value
      .split(/\s+/)
      .join('')
      .match(/^[0-9]*$/gm)
  },
  isNonNumeric: (value) => /\d/gi.test(value),
  isOnlyNumeric: (value) => !/\d/gi.test(value),
  isDDDValid: (value = '') => {
    if (!value) return true

    let onlyNums = String(value.match(/\d+/g) || '')
    let phoneCode =
      onlyNums && typeof onlyNums === 'string'
        ? Number(onlyNums.substring(0, 2))
        : ''
    const validPhoneCodeRegex = new RegExp(
      '(11|12|13|14|15|16|17|18|19|21|22|24|27|28|31|32|33|34|35|37|38|41|42|43|44|45|46|47|48|49|51|53|54|55|61|62|63|64|65|66|67|68|69|71|73|74|75|77|79|81|82|83|84|85|86|87|88|89|91|92|93|94|95|96|97|98|99)',
      'gi'
    )

    return !validPhoneCodeRegex.test(phoneCode)
  },
  isRgValid: (value) => {
    return !(
      value &&
      value.length <= 12 &&
      // eslint-disable-next-line no-useless-escape
      /^(\d{1,2})(\.)?(\d{3})(\.)?(\d{3})(\-)?([\dX])$/.test(value)
    )
  },
  fieldLengthLessThan: (value, param) => {
    if (!value) return

    return value.length > param
  },
  containNameTitle: (value) => {
    if (!value) return

    const possibleTitle = value.trim().split(' ')[0]
    const titles = ['dr', 'dra', 'sr', 'sra', 'eng']

    return titles.some((title) => possibleTitle.toLowerCase() === title)
  },
  isNameComplete: (value) => {
    if (!value) return

    const splittedName = value.trim().split(' ')
    const firstName = splittedName[0]
    const lastName = splittedName[splittedName.length - 1]

    if (firstName.length < 2 || lastName.length < 2) return true
  },
  isValidText: (text) => !helpers.isSecureText(text, null),
  isValidName: (name) => !helpers.validName(name, false),
  validateIfHasSpecialCharactersOnly: (value = '') => {
    return (
      value
        .replace(helpers.validCharRegex, '')
        .replace(helpers.removeSpacesRegex, '').length === 0
    )
  }
}

export default validators
