import { Helmet } from 'react-helmet'

import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { If } from '@itau-loans-www/shopping/src/components'

import { getStaticImageByFilename } from './helpers'

const Img = ({
  file,
  isToPreload = false,
  loading = 'lazy',
  fetchpriority = 'low',
  ...props
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    formats: [WEBP]
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      `}
      render={({ images }) => {
        const image = getStaticImageByFilename({
          images,
          filename: file
        })

        const imageData = image?.node?.childImageSharp?.gatsbyImageData

        const preloadData = imageData?.images?.fallback

        return (
          <If
            condition={image}
            renderIf={
              <>
                <If
                  condition={isToPreload}
                  renderIf={
                    <Helmet>
                      <link
                        as="image"
                        rel="preload"
                        href={preloadData?.src}
                        imagesizes={preloadData?.sizes}
                        imagesrcset={preloadData?.srcSet}
                        fetchpriority={fetchpriority}
                        {...props}
                      />
                    </Helmet>
                  }
                />
                <GatsbyImage
                  loading={loading}
                  image={imageData}
                  fetchpriority={fetchpriority}
                  {...props}
                />
              </>
            }
          />
        )
      }}
    />
  )
}

export default Img
