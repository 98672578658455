import classnames from 'classnames'

import Conditions from './components/Conditions'
import './second-offer-card.scss'

const SecondOffer = ({ className, proposal, index }) => {
  const classNames = classnames('second-offer-card', {
    [className]: className
  })

  return (
    <div className={classNames}>
      <Conditions proposal={proposal} offerIdx={index} />
    </div>
  )
}

export default SecondOffer
