import cep from '@mobi/utils/masks/cep'
import cpf from '@mobi/utils/masks/cpf'
import date from '@mobi/utils/masks/date'
import phone from '@mobi/utils/masks/phone'
import { createNumberMask } from 'text-mask-addons'

const currency = (integerLimit = null) => {
  return createNumberMask({
    suffix: '',
    prefix: 'R$ ',
    allowNegative: false,
    requiredDecimal: false,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    integerLimit
  })
}

const _month = createNumberMask({
  suffix: ' mês',
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: false
})

const _months = createNumberMask({
  suffix: ' meses',
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: false
})

const month = (value = '') => {
  const numberOnly = value.replace(/\D/gi, '')
  const isOneMonth = numberOnly === '1'

  return isOneMonth ? _month(numberOnly) : _months(numberOnly)
}

const number = [/\d/]

const masks = {
  currency: currency(),
  zipCode: cep,
  cpf,
  month,
  number,
  phone,
  date
}

export default masks
