import IconSeguranca from '@mobi/ds/static/icons/outlined/seguranca.svg'

import './safe-tag.scss'

const SafeTag = () => {
  return (
    <div className="safe-tag">
      <IconSeguranca />
      <p className="safe-tag__text">Ambiente seguro</p>
    </div>
  )
}

export default SafeTag
