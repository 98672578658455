import { IdsIcon } from '@ids/react'
import AriaLabel from '@mobi/components/AriaLabel'
import classnames from 'classnames'

import './tracking.scss'

const createMarkup = (data) => ({
  __html: data
})

const Tracking = ({ trackList }) => {
  const trackingBaseClass = 'tracking'

  const checkFailedStatus = () => {
    const hasFailedStatus = trackList.find((step) => step.status === 'failed')

    if (hasFailedStatus !== undefined) {
      return true
    }

    return false
  }

  const handleAriaLabel = (step, status) => {
    if (status === 'open') {
      return `Passo ${step} em aberto`
    }

    if (status === 'ongoing') {
      return `Passo ${step} em andamento`
    }

    if (status === 'done') {
      return `Passo ${step} concluído`
    }

    if (status === 'failed') {
      return `Passo ${step} paralizado`
    }

    return ''
  }

  const renderTrack = trackList.map((step, index) => {
    return (
      <li
        key={index}
        className={`${trackingBaseClass}__step ${trackingBaseClass}__step--${step.status}`}
      >
        <AriaLabel
          ariaText={
            step?.disableAriaHidden
              ? `${handleAriaLabel(index + 1, step.status)}`
              : `${handleAriaLabel(index + 1, step.status)}, ${step.title} ${
                  step.text ?? ''
                } ${step.childrenAriaLabel ?? ''}`
          }
        >
          <div
            className={`${trackingBaseClass}__container`}
            aria-hidden={step?.disableAriaHidden ? 'false' : 'true'}
          >
            <i className={`${trackingBaseClass}__icon`}>
              {step.status === 'done' && (
                <IdsIcon contrast="onBrand" color="white">
                  chat_enviado
                </IdsIcon>
              )}
              {step.status !== 'done' && (
                <IdsIcon variant="neutral">confirmacao</IdsIcon>
              )}
            </i>
            <span
              className={`${trackingBaseClass}__title`}
              aria-hidden={step?.disableAriaHidden ? 'false' : 'true'}
            >
              <strong>{step.title}</strong>
              {step.text && step.text !== '' && <br />}
              {step.text}
              {typeof step.children === 'string' ? (
                <span
                  {...{ dangerouslySetInnerHTML: createMarkup(step.children) }}
                />
              ) : (
                step.children
              )}
            </span>
          </div>
        </AriaLabel>
      </li>
    )
  })

  const trackingClasses = classnames(trackingBaseClass, {
    [`${trackingBaseClass}--failed`]: checkFailedStatus()
  })

  return <ul className={trackingClasses}>{renderTrack}</ul>
}

export default Tracking
