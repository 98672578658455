import { lazy, Suspense } from 'react'

import { If, DsIcons } from '@itau-loans-www/shopping/src/components'
import { isClient } from 'utils'

const LocalIcon = ({ name, className = '', ...props }) => {
  const IconComponent = lazy(() =>
    import(`@itau-loans-www/shopping/src/assets/icons/${name}.svg`)
  )

  return (
    isClient() && (
      <Suspense fallback={<></>}>
        <IconComponent className={className} {...props} />
      </Suspense>
    )
  )
}

const Icon = ({ name, type, isLocal = false, className = '', ...props }) => {
  const classes = `icon ${name} ${className}`

  return (
    <If
      condition={isLocal}
      renderIf={<LocalIcon name={name} className={classes} {...props} />}
      renderElse={
        <DsIcons icon={name} type={type} className={classes} {...props} />
      }
    />
  )
}

export default Icon
