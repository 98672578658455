import './optin.scss'

const OptIn = () => (
  <div class="optin">
    <p>
      Ao continuar, autorizo os gestores de banco de dados do cadastro positivo
      a disponibilizarem ao Itaú Unibanco S.A o meu histórico de crédito. Estou
      ciente dos{' '}
      <a
        href="https://www.itau.com.br/seguranca/termo-autorizacao"
        target="_blank"
        rel="noreferrer"
      >
        termos de autorização
      </a>{' '}
      e que poderei efetuar o{' '}
      <a
        href="https://www.itau.com.br/cadastro-positivo/"
        target="_blank"
        rel="noreferrer"
      >
        cancelamento
      </a>{' '}
      a qualquer momento.
    </p>
    <p>
      Autorizo também a consulta ao{' '}
      <a
        href="https://www.itau.com.br/seguranca/sistema-informacao-credito/"
        target="_blank"
        rel="noreferrer"
      >
        sistema de informações de crédito
      </a>{' '}
      (SCR) do Banco Central, estou ciente que meus dados serão registrados no
      SCR e de acordo com a{' '}
      <a
        href="https://www.itau.com.br/privacidade/"
        target="_blank"
        rel="noreferrer"
      >
        política de privacidade
      </a>
      .
    </p>
  </div>
)

export default OptIn
