import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'
import { storage } from 'utils'

const finishSimulationOnWpp = async () => {
  const simulationOptionUuid = storage.session.getItem('simulationOptionUuid')

  const { data } = await defaultLeadsApiCall({
    endpoint: 'proposals',
    method: 'post',
    body: {
      contact_type: 'whatsapp',
      simulation_option_uuid: simulationOptionUuid,
      inbound: true
    }
  })

  return data
}

export default { finishSimulationOnWpp }
