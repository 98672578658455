import Conditions from './components/Conditions'
import './hero-offer.scss'

const HeroOffer = ({ proposal }) => {
  return (
    <section className="hero-offer">
      <div data-cy="cy-div-offer" className="hero-offer__container">
        <Conditions proposal={proposal} />
      </div>
    </section>
  )
}

export default HeroOffer
