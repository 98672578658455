import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { goTo } from 'utils'
import { resolvePath } from 'utils'

const useHeader = ({ backRoute }) => {
  const previousLink = resolvePath('home')
  const { leadData } = useLead()

  const prevAction = (event) => {
    event.preventDefault()

    if (backRoute) {
      return goTo(backRoute)
    }

    return goTo(leadData?.previousStep || previousLink)
  }

  const utms = {
    utmsHeaderOpenAccount:
      '&utm_term=shopping-x-lp-header&utm_sou=shopping&utm_med=x&utm_camp=lp&utm_cont=header&utm_ter=19',
    utmsHeaderMenuCheckingAccount:
      'shopping-x-lp-headerparavocemenu&utm_sou=shopping&utm_med=x&utm_camp=lp&utm_cont=headerparavocemenu&utm_ter=49',
    utmsHeaderMenuOpenAccount:
      'shopping-x-lp-headerparavocebanner&utm_sou=shopping&utm_med=x&utm_camp=lp&utm_cont=headerparavocebanner&utm_ter=31'
  }

  return {
    prevAction,
    utmsHeaderOpenAccount: utms?.utmsHeaderOpenAccount,
    utmsHeaderMenuCheckingAccount: utms?.utmsHeaderMenuCheckingAccount,
    utmsHeaderMenuOpenAccount: utms?.utmsHeaderMenuOpenAccount
  }
}

export { useHeader }
