import './shimmer-loader.scss'

const Shimmer = ({
  margin = '0',
  marginDesktop = null,
  width = '100%',
  widthDesktop = null,
  height = '100%',
  heightDesktop = null,
  isVisible = true,
  children
}) => {
  const desktopMargin = marginDesktop || margin
  const desktopWidth = widthDesktop || width
  const desktopHeight = heightDesktop || height

  return (
    <div
      data-is-visible={isVisible}
      className="shimmer-loader"
      data-testid="shimmer-loader"
      style={{
        '--margin-mobile': margin,
        '--margin-desktop': desktopMargin,
        '--width-mobile': width,
        '--width-desktop': desktopWidth,
        '--height-mobile': height,
        '--height-desktop': desktopHeight
      }}
    >
      {children}
    </div>
  )
}

export default Shimmer
