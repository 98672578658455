import logoAppUrl from '@itau-loans-www/shopping/src/assets/images/app-itau-logo.webp'
import { DsHeader } from '@itau-loans-www/shopping/src/components'

import './header.scss'
import { useHeader } from './hooks'

const Header = ({ isLanding = false, backRoute = '' }) => {
  const {
    prevAction,
    utmsHeaderMenuCheckingAccount,
    utmsHeaderMenuOpenAccount,
    utmsHeaderOpenAccount
  } = useHeader({ backRoute })

  return (
    <DsHeader
      home={isLanding}
      prevUrl=""
      prevLabel="Voltar"
      prevAction={prevAction}
      logoLink={'/'}
      onClickLogo={'/'}
      logoAppUrl={logoAppUrl}
      utmsHeaderOpenAccount={utmsHeaderOpenAccount}
      utmsHeaderMenuCheckingAccount={utmsHeaderMenuCheckingAccount}
      utmsHeaderMenuOpenAccount={utmsHeaderMenuOpenAccount}
    />
  )
}

export default Header
