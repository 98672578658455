const data = {
  linksInfos: [
    {
      text: 'termos de uso e privacidade',
      link: 'https://www.itau.com.br/privacidade/'
    },
    {
      text: 'segurança',
      link: 'https://www.itau.com.br/seguranca/'
    },
    {
      text: 'precisa de ajuda?',
      link: 'https://www.itau.com.br/atendimento-itau/para-voce/'
    }
  ],
  contactInfo: [
    {
      title: 'SAC Itaú Consignado',
      tel: '(Capitais e regiões metropolitanas) <br /> 4004 4828'
    },
    {
      title: 'SAC Itaú Consignado',
      tel: '(Demais regiões) <br /> 0800 970 4828'
    },
    {
      title: 'Ouvidoria Banco Itaú Consignado',
      tel: '0800 724 2101'
    },
    {
      title: 'SAC Itaú Unibanco',
      tel: '0800 728 0728'
    },
    {
      title: 'Ouvidoria Itaú Unibanco',
      tel: '0800 570 0011'
    }
  ],
  copyright: (
    <>
      © 2024 Banco Itaú Consignado S.A CNPJ: 33.885.724/0001-19 <br /> © 2024
      Itaú Unibanco Holding S.A. CNPJ: 60.872.504/0001-23
    </>
  )
}

export default data
