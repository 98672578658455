import { If } from '@itau-loans-www/shopping/src/components'

import './Modal-item.scss'

const TextBlock = ({
  item = '',
  value = '',
  customClassName = '',
  divider = true
}) => {
  return (
    <>
      <div className={`text-block ${customClassName}`}>
        <p
          className="text-block__item"
          dangerouslySetInnerHTML={{ __html: item }}
        />
        <strong
          className="text-block__value"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </div>
      <If
        condition={divider}
        renderIf={<hr class="ids-divider text-block__divider" />}
      />
    </>
  )
}

export default TextBlock
