import { useEffect, useRef, useState, useCallback } from 'react'

const useFeedback = () => {
  const [feedback, setFeedback] = useState({
    message: '',
    type: '',
    show: false
  })
  const AlertRef = useRef()

  const closeAlert = () => setFeedback((state) => ({ ...state, show: false }))
  const show = feedback?.show ?? false

  const type = feedback?.type
  const message =
    feedback?.message ??
    'Não foi possível completar sua solicitacão. Por favor, tente novamente mais tarde.'

  const ariaHidden = show ? null : 'true'
  const role = show ? 'alertdialog' : null

  const showFeedback = useCallback(
    ({ detail }) => {
      setFeedback(detail)
      if (detail?.show) {
        AlertRef.current.querySelector('.ds-alert__close').focus()
      }
    },
    [AlertRef]
  )

  useEffect(() => {
    window.addEventListener('showFeedback', showFeedback)

    return () => window.removeEventListener('showFeedback', showFeedback)
  }, [showFeedback])

  return {
    AlertRef,
    closeAlert,
    showFeedback,
    show,
    type,
    message,
    ariaHidden,
    role,
    feedback,
    setFeedback
  }
}

export default useFeedback
