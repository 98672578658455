import { Tracking } from '@itau-loans-www/shopping/src/components'

import './time-line.scss'

const TimeLine = ({
  className,
  title,
  subtitle = 'Próximos passos',
  steps
}) => {
  return (
    <div className={`time-line ${className}`}>
      <h2 className="time-line__title">{title}</h2>

      <div className="time-line__steps-wrapper">
        <p className="time-line__steps-text">{subtitle}</p>

        <Tracking trackList={steps} />
      </div>
    </div>
  )
}

export default TimeLine
