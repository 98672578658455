import { Carousel, CarouselItem } from '@mobi/ds'

import Img from 'components/Img'

import { SLIDER_CONFIG } from './constants'
import './slider-steps.scss'

const SliderSteps = ({ carouselConfig = SLIDER_CONFIG, sliderData }) => {
  return (
    <section className="slider-steps">
      <div className="slider-steps__carousel-wrapper">
        <Carousel
          indentifier="slider-steps"
          variant="slider"
          bullets
          arrows
          transition
          options={carouselConfig}
          className="slider-steps__carousel"
        >
          {sliderData.map(({ img, title, text }, index) => (
            <CarouselItem key={title}>
              <div className="slider-steps__step">
                <div className="'slider-steps__header'">
                  <Img file={img} className="slider-steps__img" />
                </div>
                <p className="slider-steps__number">{`0${index + 1}.`}</p>
                <p className="slider-steps__title">{title}</p>
                <p className="slider-steps__text">{text}</p>
              </div>
            </CarouselItem>
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default SliderSteps
