import { finishProposalOnCardCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'
import { DirectionalButton } from 'components'

import AdditionalData from '../../../../components/AdditionalData'
import Disclaimer from '../../../../components/Disclaimer'
import './credit-card-offer.scss'

// import { proposal } from './mock'

const CreditCardOffer = (props) => {
  const { currentProposal } = props

  const {
    title,
    highlights,
    productCode,
    buttonText,
    buttonTrackingName,
    image
  } = currentProposal
  // } = proposal

  return (
    <div className="credit-card-hero-offer-box">
      <figure className="credit-card-hero-offer__img-wrapper">
        <img
          src={image}
          alt={title}
          title={title}
          className="credit-card-img"
          width="133"
          height="120"
        />
      </figure>
      <div className="credit-card-hero-offer">
        <div className="credit-card-hero-offer__header--mobile">
          <p className="credit-card-hero-offer__title">Peça já o seu cartão</p>
          <p className="credit-card-hero-offer__title">{title}</p>
        </div>
        <div className="credit-card-hero-offer__header--desktop">
          <p className="credit-card-hero-offer__title">
            Peça já o seu cartão {title}
          </p>
        </div>
        <AdditionalData list={highlights} />

        <DirectionalButton
          {...props}
          id={`credit-card-hero-offer-${productCode}-btn`}
          buttonTracking={buttonTrackingName}
          buttonText={buttonText}
          callback={finishProposalOnCardCallback}
          dataTestid="hero-offer-credit-card-offer-btn"
          className="directional-button__hero-offer-credit-card"
        />

        <Disclaimer />
      </div>
    </div>
  )
}

export default CreditCardOffer
