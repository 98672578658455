import { DsFooter } from '@itau-loans-www/shopping/src/components'

import data from './data.js'

const Footer = () => {
  return (
    <DsFooter
      linksInfos={data?.linksInfos}
      contactInfos={data?.contactInfo}
      copyrightText={data?.copyright}
    />
  )
}

export default Footer
