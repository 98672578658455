import { IdsActionButton, IdsContextualButton } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import './see-offers-btn.scss'

const SeeOffersButton = ({
  isContextual = false,
  isCrossSell = false,
  id = 'VerMinhasOfertas',
  text = 'Voltar para minhas ofertas'
}) => {
  const onClick = async () => {
    try {
      analytics.track('buttonClick', {
        currentPage: 'telas-finais-de-redirect-do-produto',
        detail: 'click-voltar-minhas-ofertas'
      })

      if (!isCrossSell) {
        await goTo('/proposta/ofertas')
        return
      }
      const nextStep = await http.proposal.crossSell()
      await goTo(nextStep)
    } catch (error) {
      console.error('SeeOffersButton | ', error)

      const payload = {
        error: error?.message,
        requestURL: error?.request?.responseURL
      }

      DDPageError({
        name: 'SeeOffersButton',
        payload
      })
    }
  }

  return (
    <If
      condition={isContextual}
      renderIf={
        <IdsContextualButton
          id={id}
          onClick={onClick}
          className="see-offers-btn"
          data-testid="see-offers-button"
        >
          {text}
        </IdsContextualButton>
      }
      renderElse={
        <IdsActionButton
          variant="secondary"
          id={id}
          onClick={onClick}
          className="see-offers-btn"
          data-testid="see-offers-button"
        >
          {text}
          <span class="ids-icon ids-icon--small" aria-hidden>
            seta_direita
          </span>
        </IdsActionButton>
      }
    />
  )
}

export default SeeOffersButton
