import './partners.scss'

const Partners = ({ content: { title = '', logos = [] } }) => {
  return (
    <div className="sms-page-partners">
      <p className="sms-page-partners__title">{title}</p>
      <div className="sms-page-partners__logo">
        {logos.map((logo, index) => (
          <img key={index} src={logo.img} alt={logo.alt} />
        ))}
      </div>
    </div>
  )
}

export default Partners
