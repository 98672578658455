import messages from './messages'
import validators from './validators'

const useValidate = (rules = {}) => {
  const validate = (value) => {
    const errorMessage = Object.entries(rules).reduce((acc, [name, param]) => {
      const validator = validators[name]

      if (validator(value, param)) {
        return messages[name](param)
      }

      return acc
    }, '')

    return errorMessage
  }

  return validate
}

const useValidateHF = (rules = {}) => {
  const validate = (value) => {
    const errorMessage = Object.entries(rules).reduce((acc, [name, param]) => {
      const validator = validators[name]

      if (validator(value, param)) {
        return messages[name](param)
      }

      return acc
    }, true)

    return errorMessage
  }

  return validate
}

export { useValidate, useValidateHF }
