import { useState } from 'react'

import { IdsTabs } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'

const ModalTabs = ({ children }) => {
  const [tabs, setTabs] = useState(
    children.map((item, idx) => ({
      label: item.props.title,
      selected: idx === 0
    }))
  )
  const selectedIndex = tabs.findIndex((tab) => tab.selected)
  const hasAtLeastTwoChild = children?.length

  return (
    <If
      condition={hasAtLeastTwoChild}
      renderIf={
        <div className="modal-tabs" data-testid="modal-tabs">
          <IdsTabs tabs={tabs} setTabs={setTabs} id="ids-react-tabs" />
          {children?.filter((_, index) => index === selectedIndex)}
        </div>
      }
      renderElse={<div />}
    />
  )
}

export default ModalTabs
