import { If, Alert } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'

import './feedback.scss'
import useFeedback from './hooks'

const Feedback = () => {
  const { AlertRef, closeAlert, show, type, message, ariaHidden, role } =
    useFeedback()

  const className = classnames('feedback', {
    '-show': show
  })

  return (
    <section
      role={role}
      ref={AlertRef}
      className={className}
      aria-hidden={ariaHidden}
    >
      <If
        condition={show && type}
        renderIf={
          <Alert
            isVisible={show}
            message={message}
            closeAlert={closeAlert}
            variant={type}
          />
        }
      />
    </section>
  )
}

export default Feedback
